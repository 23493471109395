import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from 'components/button';
import buttonVariants from 'components/button/constants/buttonVariants';
import Link from 'components/link';

import './styles.scss';

function Hero() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.homeHeader != null);

  const { headline, subtext, primaryButton, secondaryButton, sponsor } =
    activeEdge.node.frontmatter.homeHeader;

  return (
    <section className="hero">
      <div className="hero_container grid">
        <div className="start-1 col-6 col-xl-4 col-md-full">
          <div>
            <h1 className="text-title hero_h1">{headline}</h1>
            <p className="hero_p">{subtext}</p>
            <div className="hero_buttons">
              {/* <Link to={primaryButton.link}>
                <Button>{primaryButton.text}</Button>
              </Link> */}
              <Link to={secondaryButton.link}>
                <Button>
                  {secondaryButton.text}
                </Button>
              </Link>
            </div>
            <div className="hero_platforms">
              <Link to={'https://play.google.com/store/apps/details?id=com.pintarnyakerja&referrer=utm_source%3Dwebsite%26utm_medium%3Dwebsite_banner%26utm_term%3Dwebsitebanner%26utm_campaign%3Dwebsitebanner_homepage'}>
                <StaticImage
                  alt="play store"
                  className="hero_platforms_image"
                  src="../../../assets/home/hero/play-store.svg"
                  placeholder="blurred"
                />
              </Link>
              {/* <StaticImage
                alt="app store"
                className="hero_platforms_image"
                src="../../../assets/home/hero/app-store.svg"
                placeholder="blurred"
              /> */}
            </div>
            <div className="hero_sponsor">
              <p className="hero_sponsor_p">{sponsor}</p>
              <StaticImage
                alt="kominfo"
                className="hero_sponsor_image"
                src="../../../assets/home/hero/kominfo.png"
                placeholder="blurred"
              />
            </div>
          </div>
        </div>
        <div className="hero_image">
          <StaticImage
            alt="phone"
            src="../../../assets/home/hero/phone.png"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;

const query = graphql`
  query HeroQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            homeHeader {
              headline
              primaryButton {
                link
                text
              }
              secondaryButton {
                text
                link
              }
              sponsor
              subtext
            }
          }
        }
      }
    }
  }
`;
