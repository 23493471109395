import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import LinkCard from 'components/@home/linkCard';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './styles.scss';

function Links() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.homeLinks != null);

  const { headline, employer, jobseeker, link } = activeEdge.node.frontmatter.homeLinks;

  const { width: windowWidth } = useWindowDimensions();

  return (
    <section className="links grid">
      <div className="start-1 col-full">
        <h1 className="links_headline">{headline}</h1>
      </div>
      <div className="start-1 col-6 col-xl-4 col-lg-full">
        <LinkCard
          color="purple"
          headline={
            windowWidth < 992
              ? jobseeker.button.headlineMobile
              : jobseeker.button.headline
          }
          subtext={
            windowWidth < 992
              ? jobseeker.button.subtextMobile
              : jobseeker.button.subtext
          }
          link={windowWidth < 992 ? jobseeker.button.linkMobile : jobseeker.button.link}
        />
        <div className="links_benefits___purple">
          {jobseeker.benefits.map((benefit) => (
            <div key={benefit.text} className="links_item">
              <div className="links_item_icon___purple">
                <img alt="icon" className="links_item_image" src={benefit.icon} />
              </div>
              <p className="links_item_text">{benefit.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="col-6 col-xl-4 col-lg-full">
        <LinkCard
          color="blue"
          headline={
            windowWidth < 992
              ? employer.button.headlineMobile
              : employer.button.headline
          }
          subtext={
            windowWidth < 992 ? employer.button.subtextMobile : employer.button.subtext
          }
          link={employer.button.link}
        />
        <div className="links_benefits___blue">
          {employer.benefits.map((benefit) => (
            <div key={benefit.text} className="links_item">
              <div className="links_item_icon___blue">
                <img alt="icon" className="links_item_image" src={benefit.icon} />
              </div>
              <p>{benefit.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Links;

const query = graphql`
  query LinksQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            homeLinks {
              headline
              jobseeker {
                button {
                  headline
                  headlineMobile
                  subtext
                  subtextMobile
                  link
                  linkMobile
                }
                benefits {
                  icon
                  text
                }
              }
              employer {
                button {
                  headline
                  headlineMobile
                  subtext
                  subtextMobile
                  link
                }
                benefits {
                  icon
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
