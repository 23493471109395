import React, { useState } from 'react';

import AngleRight from 'assets/icons/angle-right.inline.svg';

import './styles.scss';

function QuestionCard({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  function onClick() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="questioncard">
      <button className="questioncard_button text-gilmer-bold" onClick={onClick}>
        {question}
        <AngleRight
          className={`questioncard_icon ${isOpen ? 'questioncard_icon___open' : ''}`}
        />
      </button>
      {isOpen && (
        <div className="questioncard_answer">
          <p className="questioncard_answer_p">{answer}</p>
        </div>
      )}
    </div>
  );
}

export default QuestionCard;
