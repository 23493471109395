import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Button from 'components/button';
import Link from 'components/link';
import buttonVariants from 'components/button/constants/buttonVariants';
import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './styles.scss';

function Benefits() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.benefits != null);

  const { jobseeker, employer } = activeEdge.node.frontmatter.benefits;

  const { width: windowWidth } = useWindowDimensions();

  return (
    <section className="benefits">
      <div className="grid">
        <div className="benefits_image___left start-1 col-6 col-xl-4 col-lg-3 col-md-full">
          <img
            alt="jobseeker benefit"
            src={jobseeker.image}
            className="benefits_image"
          />
        </div>
        <div className="col-4 col-xl-4 col-lg-3 col-md-full">
          <h1>{jobseeker.headline}</h1>
          <ul>
            {jobseeker.points.map((item) => (
              <li key={item} className="benefits_item text-gilmer-medium">
                {item}
              </li>
            ))}
          </ul>
          <Link to={jobseeker.button.link} className="benefits_link">
            <Button className="benefits_button" variant={buttonVariants.SECONDARY}>
              {windowWidth < 992 ? jobseeker.button.textMobile : jobseeker.button.text}
              <ArrowRight className="button_icon" />
            </Button>
          </Link>
        </div>
      </div>
      <div className="grid">
        <div className="start-2 start-xl-1 col-4 col-xl-4 col-lg-3 col-md-full">
          <h1>{employer.headline}</h1>
          <ul>
            {employer.points.map((item) => (
              <li key={item} className="benefits_item text-gilmer-medium">
                {item}
              </li>
            ))}
          </ul>
          <Link to={employer.button.link} className="benefits_link">
            <Button className="benefits_button" variant={buttonVariants.SECONDARY}>
              {employer.button.text}
              <ArrowRight className="button_icon" />
            </Button>
          </Link>
        </div>
        <div className="benefits_image___right col-6 col-xl-4 col-lg-3 col-md-full">
          <img alt="employer benefit" src={employer.image} className="benefits_image" />
        </div>
      </div>
    </section>
  );
}

export default Benefits;

const query = graphql`
  query BenefitsQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            benefits {
              jobseeker {
                button {
                  text
                  textMobile
                  link
                }
                headline
                points
                image
              }
              employer {
                button {
                  text
                  link
                }
                headline
                points
                image
              }
            }
          }
        }
      }
    }
  }
`;
