import React, { useContext, useEffect, useState } from 'react';

import {
  CarouselContext,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import ArrowRight from 'assets/icons/arrow-right.inline.svg';
import ArrowLeft from 'assets/icons/arrow-left.inline.svg';

import Testimonial from '../testimonial';

import './styles.scss';

function Carousel({ headline, subtext, stories }) {
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <>
      <div className="carousel start-1 col-4 col-xl-3 col-lg-2 col-md-full">
        <h1>{headline}</h1>
        <p>{subtext}</p>
        <div className="carousel_buttons">
          <ButtonBack className="carousel_button">
            <ArrowLeft className="carousel_button_icon" />
          </ButtonBack>
          <ButtonNext className="carousel_button">
            <ArrowRight className="carousel_button_icon" />
          </ButtonNext>
        </div>
      </div>
      <div className="col-8 col-xl-6 col-lg-4 col-md-full">
        <Slider className="carousel_slider">
          {stories.map((story, index) => (
            <Slide key={story.name} index={index}>
              <Testimonial
                isActive={index === Math.ceil(currentSlide)}
                content={story.content}
                name={story.name}
                image={story.image}
              />
            </Slide>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default Carousel;
