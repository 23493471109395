import React from 'react';

import Hero from 'widgets/@home/hero';
import Partners from 'widgets/@home/partners';
import Links from 'widgets/@home/links';
import Benefits from 'widgets/@home/benefits';
import Faq from 'widgets/@home/faq';
import Stories from 'widgets/@home/stories';
import Communities from 'widgets/@home/communities';
import Layout from 'components/layout';
import Seo from 'components/seo';
import Rewards from 'widgets/@home/rewards';
import HeroSlider from 'widgets/@home/hero-slider'

function Home() {
  return (
    <Layout isShowSnackbar={true}>
      <Seo title="Cari Lowongan Kerja Terbaru untuk Lulusan SD-S1 | Pintarnya" description='Situs pencarian lowongan kerja terdekat via online di Jakarta, Medan, Bandung, Surabaya, & kota lain di Indonesia. Pintarnya terdaftar & diawasi KOMINFO.' />
      <Hero />
      <Links />
      <Partners />
      {/* <Rewards /> */}
      <Benefits />
      <Faq />
      <Stories />
      <Communities />
    </Layout>
  );
}

export default Home;
