import React from 'react';

import './styles.scss';

function Testimonial({ name, content, image, isActive }) {
  return (
    <div className={`testimonial ${isActive ? 'testimonial___active' : ''}`}>
      <p className="testimonial_body">{content}</p>
      <div className="testimonial_footer">
        <img alt="person" src={image} width={52} height={52}/>
        <p className="testimonial_name">
          <strong>{name}</strong>
        </p>
      </div>
    </div>
  );
}

export default Testimonial;
