import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import useWindowDimensions from 'hooks/useWindowDimensions';

import './styles.scss';

function HomePartners() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find(
    (edge) => edge?.node?.frontmatter?.homePartners != null
  );

  const { headline, companies, companiesHeadline } =
    activeEdge.node.frontmatter.homePartners;

  const { width: windowWidth } = useWindowDimensions();

  let visibleSlides = 5;

  if (windowWidth < 330) {
    visibleSlides = 1.25;
  } else if (windowWidth < 400) {
    visibleSlides = 1.5;
  } else if (windowWidth < 576) {
    visibleSlides = 1.75;
  } else if (windowWidth < 768) {
    visibleSlides = 3;
  } else if (windowWidth < 992) {
    visibleSlides = 4;
  }

  return (
    <section className="homepartners">
      <p className="homepartners_headline">{headline}</p>
      <CarouselProvider
        className="homepartners_carousel"
        naturalSlideWidth={86}
        naturalSlideHeight={200}
        totalSlides={windowWidth < 992 ? companies.length - 1 : companies.length}
        visibleSlides={visibleSlides}
        style={{ height: 60 }}
      >
        <Slider>
          {companies.map((company, idx) => (
            <Slide key={company.name}>
              {windowWidth < 992 && idx === 4 ? null : (
                <img src={company.logo} alt={company.name} />
              )}
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
      <p className="homepartners_companies">
        <b>100+ </b> {companiesHeadline}
      </p>
    </section>
  );
}

export default HomePartners;

const query = graphql`
  query HomePartnersQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            homePartners {
              headline
              companies {
                logo
                name
              }
              companiesHeadline
            }
          }
        }
      }
    }
  }
`;
