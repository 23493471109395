import React from 'react';

import Link from 'components/link';

import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import './styles.scss';

function LinkCard({ color, headline, subtext, link }) {
  return (
    <Link to={link} className={`linkcard linkcard___${color}`}>
      <div className="linkcard_text">
        <h3 className="linkcard_text_h3">{headline}</h3>
        <p className="linkcard_text_p">{subtext}</p>
      </div>
      <div className="linkcard_arrow">
        <ArrowRight />
      </div>
    </Link>
  );
}

export default LinkCard;
