import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Carousel from 'components/@home/carousel';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './styles.scss';

function Stories() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.stories != null);

  const { headline, headlineMobile, subtext, stories } = activeEdge.node.frontmatter.stories;

  const { width: windowWidth } = useWindowDimensions();

  let visibleSlides = 2.25;

  if (windowWidth < 576) {
    visibleSlides = 1.125;
  } else if (windowWidth < 768) {
    visibleSlides = 2;
  } else if (windowWidth < 992) {
    visibleSlides = 1.3;
  } else if (windowWidth < 1200) {
    visibleSlides = 1.75;
  }

  return (
    <section>
      <CarouselProvider
        className="stories grid"
        naturalSlideWidth={320}
        naturalSlideHeight={480}
        totalSlides={windowWidth < 576 ? stories.length : stories.length+1}
        visibleSlides={visibleSlides}
      >
        <Carousel headline={windowWidth < 992 ? headlineMobile : headline} subtext={subtext} stories={stories} />
      </CarouselProvider>
    </section>
  );
}

export default Stories;

const query = graphql`
  query StoriesQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            stories {
              headline
              headlineMobile
              subtext
              stories {
                content
                name
                image
              }
            }
          }
        }
      }
    }
  }
`;
