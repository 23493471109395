import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from 'components/button';
import Link from 'components/link';
import buttonVariants from 'components/button/constants/buttonVariants';
import QuestionCard from 'components/@home/questionCard';

import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import './styles.scss';

function Faq() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.faq != null);

  const { headline, button, questions } = activeEdge.node.frontmatter.faq;

  return (
    <section className="faq grid">
      <div className="start-1 col-6 col-xl-4 col-lg-full faq_image">
        <StaticImage
          alt="chat bubbles"
          src="../../../assets/home/faq/bubbles.svg"
          placeholder="blurred"
        />
      </div>
      <div className="col-6 col-xl-4 col-lg-full">
        <h1>{headline}</h1>
        {questions.map((item) => (
          <QuestionCard
            key={item.question}
            question={item.question}
            answer={item.answer}
          />
        ))}
        {/* <Link className="faq_link" to={button.link}>
          <Button variant={buttonVariants.SECONDARY}>
            {button.text}
            <ArrowRight className="button_icon" />
          </Button>
        </Link> */}
      </div>
    </section>
  );
}

export default Faq;

const query = graphql`
  query FaqQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            faq {
              headline
              questions {
                question
                answer
              }
              button {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`;
