import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import './styles.scss';
import buttonVariants from 'components/button/constants/buttonVariants';
import Button from 'components/button';

import useWindowDimensions from 'hooks/useWindowDimensions';

function Communities() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.communities != null);

  const { headline, headlineMobile, subtext, groups } =
    activeEdge.node.frontmatter.communities;

  const { width: windowWidth } = useWindowDimensions();

  return (
    <section className="communities grid">
      <div className="start-1 col-6 col-xl-4 col-md-full">
        <h1>{windowWidth < 992 ? headlineMobile : headline}</h1>
        <p>{subtext}</p>
      </div>
      <div className="start-1 col-full">
        <div className="communities_groups">
          {groups.map((group) => (
            <div key={group.text} className="communities_card">
              <div className="communities_card_top">
                <img
                  alt="community icon"
                  className="communities_card_image"
                  src={group.image}
                />
                <p className="communities_card_p">{group.text}</p>
              </div>
              <a href={group.link}>
                <Button variant={buttonVariants.SECONDARY}>
                  {group.buttonText}
                  <ArrowRight className="button_icon" />
                </Button>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Communities;

const query = graphql`
  query CommunitiesQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            communities {
              headline
              headlineMobile
              subtext
              groups {
                text
                image
                buttonText
                link
              }
            }
          }
        }
      }
    }
  }
`;
